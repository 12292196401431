import React, { Component } from "react"

/* Constants */
import { utils, sound, env } from '../constants'

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Components */
import { Picture } from "@alicorn/poker-ui"
import { HoldemRates } from "../components"


/* Widget Bet */
class Bet extends Component {


    constructor() {
        super()

        this.state = {
            history: [],
        }
    }

    getChipsList = () => {
        const { currency, chips } = this.props
        return utils.getChips(currency, chips)
    }

    add = (type) => {

        const { history } = this.state
        const { setStake, ante, bonus, balance, toggleBalanceError, toggleMaxBetError, active, max } = this.props

        let list = history

        const chipsList = this.getChipsList()
        const index = chipsList.findIndex(c => c.key === active)

        let data = { ante: parseInt(ante), bonus: parseInt(bonus) }

        let tempValue = type === 'ante' ? ante ? ante : 0 : bonus ? bonus : 0

        const isAddEnabled = type === 'ante' || (type === 'bonus' && ante)

        if (index > -1 && isAddEnabled) {

            const current = chipsList[index]

            const tempBetValue = type === 'ante' ? ((parseInt(ante) + parseInt(current.value)) * 2) : parseInt(ante) * 2

            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < (parseInt(current.value) + parseInt(tempBetValue))) {
                toggleBalanceError()
                return
            }

            if (parseInt(tempValue) + parseInt(current.value) > parseInt(max)) {
                toggleMaxBetError()
                return
            }

            data[type] = parseInt(data[type]) + parseInt(current.value)

            const uid = uuidv4()
            list.push({ key: type, value: parseInt(current.value), uid })
            this.setState({ history: list })

            setStake(data)

        }
    }


    /* Clear action */
    clear = () => {
        const { setStake } = this.props
        this.setState({ history: [] })
        setStake({ ante: 0, bonus: 0 })
        sound.play()
    }


    /* Cancel action */
    cancel = () => {

        const { history } = this.state
        const { setStake, ante, bonus } = this.props

        let list = history

        if (ante > 0 && history.length > 0) {
            const last = list.pop()

            this.setState({ history: list })

            if (last.key === 'all') {
                this.setState({ history: [] })
                setStake({ ante: 0, bonus: 0 })
            } else if (last.key === 'double') {
                setStake({ ante: ante / 2, bonus: bonus / 2 })
            } else if (last.key === 'ante') {
                setStake({ ante: ante - last.value, bonus: bonus })
            } else if (last.key === 'bonus') {
                setStake({ ante: ante, bonus: bonus - last.value })
            }

            sound.play()
        }
    }


    /* Double action */
    double = () => {

        const { history } = this.state
        const { setStake, ante, bonus, balance, toggleBalanceError, toggleMaxBetError, max } = this.props

        let list = history

        const chipsList = this.getChipsList()

        const total = parseInt(ante) + parseInt(bonus)

        if (ante > 0) {

            let image = {}
            chipsList.forEach(chip => {
                if (parseInt(ante) >= chip.value) {
                    image = chip.image3d
                }
            })

            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < parseInt(total + ante * 4)) {
                toggleBalanceError()
                return
            }

            if ((parseInt(ante * 2) > parseInt(max)) || (parseInt(bonus * 2) > parseInt(max))) {
                toggleMaxBetError()
                return
            }

            const uid = uuidv4()
            list.push({ key: 'double', value: ante, uid })
            this.setState({ history: list })

            let data = { ante: parseInt(ante) * 2, bonus: parseInt(bonus) * 2 }

            setStake(data)

        }
    }


    /* Refresh action */
    refresh = () => {

        const { history } = this.state
        const { setStake, last, balance, toggleBalanceError, toggleMaxBetError, max } = this.props

        let list = history

        if ((last && last.ante) && !this.props.ante) {
            const { ante, bonus } = last
            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < (parseInt(ante * 3) + parseInt(bonus))) {
                toggleBalanceError()
                return
            }

            if ((parseInt(ante) > parseInt(max)) || (parseInt(bonus) > parseInt(max))) {
                toggleMaxBetError()
                return
            }

            const uid = uuidv4()
            list.push({ key: 'all', value: ante, uid })
            this.setState({ history: list })
            setStake(last)
        }

    }


    /* SET DEFAULT CHIP */
    setActiveChip = item => {

        const { active, setActiveChip } = this.props

        if (item.key !== active) {
            setActiveChip(item.key)
            sound.play('selectchip') // PLAY SOUND
        }
        else {
            this.add('ante')
        }

    }


    /* Draw Chips */
    _chips = () => {

        const { active } = this.props

        const list = this.getChipsList()

        return (
            <div className="chips">

                {list.map((item, index) =>
                    <div onClick={() => this.setActiveChip(item)} key={`${index}`} className={item.key === active ? `chip active-chip` : `chip`}>
                        <Picture src={`${item.image}`} alt={`CHIP ${item.value}`} />
                        <div className={`chip-value size_${utils.fontSize(item.value)}`}>{utils.counter(item.value)}</div>
                    </div>
                )}

            </div>

        )
    }


    render = () => {

        const { history } = this.state
        const { ante, bonus, last, currency, isChoise, backColor, active, chips } = this.props

        const disabled = !ante

        const cancel_disabled = history.length === 0

        return (
            <div className="bet">

                <div className="bet-panel">

                    <div className="bet-buttons">

                        {/* Clear Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.clear()}>
                            <Picture src={`${env.mediapoint}/images/bet/delete.png`} alt="Delete" />
                            <span>Очистить</span>
                        </div>

                        {/* Cancel Button */}
                        <div className={`bet-button ${cancel_disabled && 'disabled'}`} onClick={() => this.cancel()}>
                            <Picture src={`${env.mediapoint}/images/bet/cancel.png`} alt="Cancel" />
                            <span>Отменить</span>
                        </div>

                    </div>

                    {this._chips()}

                    <div className="bet-buttons">

                        {/* Double Up Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.double()}>
                            <div className="bet-title">2x</div>
                            <span>Удвоить</span>
                        </div>

                        {/* Cancel Button */}
                        <div className={`bet-button ${((!last || (last && !last.ante)) || ante) && 'disabled'}`} onClick={() => this.refresh()}>
                            <Picture src={`${env.mediapoint}/images/bet/refresh.png`} alt="Refresh" />
                            <span>Повторить</span>
                        </div>

                    </div>

                </div>

                <div className="pc-rates-outer">
                    <HoldemRates
                        ante={ante}
                        bonus={bonus}
                        currency={currency}
                        history={history}
                        fromBet={true}
                        add={type => this.add(type)}
                        active={active}
                        bet={() => { }}
                        backColor={backColor}
                        isChoise={isChoise}
                        chips={chips}
                    />
                </div>

            </div>
        )
    }

}

export default Bet