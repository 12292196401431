import React, { Component } from "react";

import { PRE_FLOP, FLOP, TURN_RIVER, GAMEPLAY, DEALING } from '../constants/status'


class Buttons extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    bet = (x) => {

        const { setAction, action } = this.props

        const style = (action ? action === x ? 'touched' : 'hidden' : '')

        return (
            <div className="btn-item">
                <div className={`u-btn u-bet ${style}`} onClick={() => !action && setAction(x)}>
                    <div className="u-btn-inner u-bet-inner">
                        <p>{x}x</p>
                        <span>BET</span>
                    </div>
                </div>
            </div>
        )

    }

    check = () => {

        const { setAction, action } = this.props

        const style = (action ? action === 'check' ? 'touched' : 'hidden' : '')

        return (
            <div className="btn-item">
                <div className={`u-btn u-check ${style}`} onClick={() => !action && setAction('check')}>
                    <div className="u-btn-inner u-check-inner">
                        <p>&#10003;</p>
                        <span>CHECK</span>
                    </div>
                </div>
            </div>
        )

    }

    pass = () => {

        const { setAction, action } = this.props

        const style = (action ? action === 'pass' ? 'touched' : 'hidden' : '')

        return (
            <div className="btn-item">
                <div className={`u-btn u-pass ${style}`} onClick={() => !action && setAction('pass')}>
                    <div className="u-btn-inner u-pass-inner">
                        <p>&#8213;</p>
                        <span>PASS</span>
                    </div>
                </div>
            </div>
        )

    }

    render = () => {

        const { action } = this.state
        const { status } = this.props

        return (
            <div className="ultimate-buttons">
                {(action || status === GAMEPLAY || status === DEALING) ? <div className="u-freezed-buttons" /> : null}
                {status === FLOP ? this.bet(2) : null}
                {(status === FLOP || status === GAMEPLAY) ? this.pass() : null}
            </div>
        )
    }
}

export default Buttons

