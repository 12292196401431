import React, { Component } from 'react'

import { utils } from '../constants'

/* Alicorn Poker UI */
import { Picture } from '@alicorn/poker-ui'

class HoldemRates extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    getChipImg = (value) => {
        const { currency, chips } = this.props
        const chipsList = utils.getChips(currency, chips)
        let current = chipsList && chipsList.length > 0 ? chipsList[0].image3d : null
        chipsList.forEach((chip) => {
            if (parseInt(value) >= parseInt(chip.value)) {
                current = chip.image3d
            }
        })
        return current
    }

    image = (type, pass = false) => {

        const { ante, bonus, result, bonusResult } = this.props

        const chipsHidden = type === 'ante' ? (result && result.result === "lose" && !result.ante) : (bonusResult && bonusResult.bonusMultiplier === 0)

        const value = type === 'ante' ? ante : bonus
        const text = type === 'ante' ? 'ANTE' : 'BONUS'

        let current = this.getChipImg(value)

        if (current && value && !pass && !chipsHidden) {
            return (
                <div className="holdem-chip-rate">
                    <Picture src={current} alt="Chip" />
                    <div className={`holdem-chip-rate-value size_${utils.fontSize(value)}`}>{utils.counter(value)}</div>
                </div>
            )
        }

        return <p>{text}</p>

    }

    /* ANTE, BLIND, TRIPS RESULT */

    imageResult = (type) => {
        const { result, bonusResult, ante, bonus } = this.props
        const tempValue = type === 'ante' ? ante : bonus
        const key = type === 'ante' ? "anteMultiplier" : 'bonusMultiplier'

        const gameResult = type === 'ante' ? result : bonusResult

        if (gameResult && tempValue && gameResult[key] && gameResult[key] > 0) {

            const value = tempValue * gameResult[key]
            let current = this.getChipImg(value)

            return (
                <div className="u-chip-image-result-multiplier-box">
                    <div className="u-chip-image-result-multiplier-text">{gameResult[key]}x</div>
                    <div className="u-chip-image-result-rate">
                        <Picture src={current} alt="Chip" />
                        <div className={`u-chip-image-result-rate-value pc size_${utils.fontSize(value)}`}>{utils.counter(value)}</div>
                    </div>
                </div>
            )
        }
    }


    /* DRAW BET */
    _bet = () => {

        const { betX, ante, result } = this.props

        if (betX) {

            const bet = ante * betX

            const chipsHidden = result && result.result === "lose"

            let current = this.getChipImg(bet)

            if (current && ante && !chipsHidden) {
                return (
                    <div className={`holdem-chip-rate holdem-chip-bet`}>
                        <Picture src={current} alt="Chip" />
                        <div className={`holdem-chip-rate-value size_${utils.fontSize(bet)}`}>{utils.counter(bet)}</div>
                    </div>
                )
            }
        }

        return <p>BET</p>

    }

    /* BET Result */
    _betResult = () => {
        const { result, ante, betX } = this.props

        const key = 'betMultiplier'

        if (result && result[key] && result[key] > 0) {

            const value = (ante * betX) * result[key]
            let current = this.getChipImg(value)

            return (
                <div className="u-chip-bet-result-multiplier-box">
                    <div className="u-chip-bet-result-multiplier-text">{result[key]}x</div>
                    <div className="u-chip-bet-result-rate">
                        <Picture src={current} alt="Chip" />
                        <div className={`u-chip-bet-result-rate-value pc size_${utils.fontSize(value)}`}>{utils.counter(value)}</div>
                    </div>
                </div>
            )
        }
    }


    render = () => {

        const { ante, bonus, isChoise, backColor, add, gameplay, action, disabled, isBetAvailable, setAction } = this.props

        const colorStyle = { backgroundColor: backColor }

        const anteAnimation = isChoise && !ante ? "animated" : ""
        const bonusAnimation = isChoise && ante && !bonus ? "animated" : ""
        const betAnimation = isBetAvailable ? "animated" : ""

        const anteAvailable = isChoise ? "cursor" : ""
        const bonusAvailable = isChoise && ante ? "cursor" : ""
        const betAvailable = isBetAvailable ? "cursor" : ""

        const disabledBonus = !ante ? "disabledBonus" : ""
        const disabledBet = isChoise ? "disabledBet" : ""

        const style = gameplay ? "game" : ""

        const chipsStyle = disabled ? "disabled" : ""

        const pass = action === 'pass'

        return (
            <div className={`holdem-chips-bottom ${style}`}>
                <div className={`holdem ${chipsStyle}`}>
                    <div className="ante-bonus">

                        <div className={`holdem-ab bonus ${bonusAvailable} ${disabledBonus}`} onClick={() => add('bonus')}>
                            <div className='holdem-ab-outer'>
                                <div className={`holdem-ab-inner ${bonusAnimation}`} style={colorStyle} />
                            </div>
                            <div className='ab-inner'>
                                {this.image('bonus')}
                                {this.imageResult('bonus')}
                            </div>
                        </div>

                        <div className={`holdem-ab ante ${anteAvailable}`} onClick={() => add('ante')}>
                            <div className='holdem-ab-outer'>
                                <div className={`holdem-ab-inner ${anteAnimation}`} style={colorStyle} />
                            </div>
                            <div className='ab-inner'>
                                {this.image('ante', pass)}
                                {this.imageResult('ante')}
                            </div>
                        </div>

                    </div>
                    <div className={`holdem-bet ${disabledBet} ${betAvailable}`} onClick={() => !action && setAction(2)}>
                        <div className='holdem-bet-outer'>
                            <div className={`holdem-bet-inner ${betAnimation}`} style={colorStyle} />
                        </div>
                        <div className='bet-inner'>
                            {this._bet()}
                            {this._betResult()}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default HoldemRates