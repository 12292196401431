import React, { Component } from 'react'

import { utils } from '../constants'

/* Alicorn Poker UI */
import { Picture } from '@alicorn/poker-ui'

class UltimateRates extends Component {
    constructor() {
        super()
        this.state = {

        }
    }

    getChipImg = (value) => {
        const { currency, chips } = this.props
        const chipsList = utils.getChips(currency, chips)
        let current = chipsList && chipsList.length > 0 ? chipsList[0].image3d : null
        chipsList.forEach((chip) => {
            if (parseInt(value) >= parseInt(chip.value)) {
                current = chip.image3d
            }
        })
        return current
    }

    image = (type, pass = false) => {

        const { ante, blind, trips, result } = this.props

        const chipsHidden = type === 'ante' ? (result && result.result === "lose" && !result.ante) : type === 'blind' ? (result && result.result === "lose") : (result && !result.playerTripsMultiplier)

        const value = type === 'ante' ? ante : type === 'blind' ? blind : trips
        const text = type === 'ante' ? 'ANTE' : type === 'blind' ? 'BLIND' : 'TRIPS'

        let current = this.getChipImg(value)

        if (current && value && !pass && !chipsHidden) {
            return (
                <div className="ultimate-chip-rate">
                    <Picture src={current} alt="Chip" />
                    <div className={`ultimate-chip-rate-value size_${utils.fontSize(value)}`}>{utils.counter(value)}</div>
                </div>
            )
        }

        return <p>{text}</p>

    }

    /* ANTE, BLIND, TRIPS RESULT */

    imageResult = (type) => {
        const { result, ante, blind, trips } = this.props
        const tempValue = type === 'ante' ? ante : type === 'blind' ? blind : trips
        const key = type === 'ante' ? "anteMultiplier" : type === 'blind' ? 'blindMultiplier' : 'playerTripsMultiplier'

        // const result = { anteMultiplier: 1, blindMultiplier: 30, playerTripsMultiplier: 500 }

        if (result && tempValue && result[key] && result[key] > 0) {

            const value = tempValue * result[key]
            let current = this.getChipImg(value)

            return (
                <div className="u-chip-image-result-multiplier-box">
                    <div className="u-chip-image-result-multiplier-text">{result[key]}x</div>
                    <div className="u-chip-image-result-rate">
                        <Picture src={current} alt="Chip" />
                        <div className={`u-chip-image-result-rate-value pc size_${utils.fontSize(value)}`}>{utils.counter(value)}</div>
                    </div>
                </div>
            )
        }
    }


    /* DRAW BET */
    _bet = () => {

        const { betX, ante, result } = this.props

        if (betX) {

            const bet = ante * betX

            const chipsHidden = result && result.result === "lose"

            let current = this.getChipImg(bet)

            if (current && ante && !chipsHidden) {
                return (
                    <div className={`ultimate-chip-rate ultimate-chip-bet`}>
                        <Picture src={current} alt="Chip" />
                        <div className={`ultimate-chip-rate-value size_${utils.fontSize(bet)}`}>{utils.counter(bet)}</div>
                    </div>
                )
            }
        }

        return <p>BET</p>

    }

    /* BET Result */
    _betResult = () => {
        const { result, ante, betX } = this.props
        const key = 'betMultiplier'
        // const result = { betMultiplier: 4 }

        if (result && result[key] && result[key] > 0) {

            const value = (ante * betX) * result[key]
            let current = this.getChipImg(value)

            return (
                <div className="u-chip-bet-result-multiplier-box">
                    <div className="u-chip-bet-result-multiplier-text">{result[key]}x</div>
                    <div className="u-chip-bet-result-rate">
                        <Picture src={current} alt="Chip" />
                        <div className={`u-chip-bet-result-rate-value pc size_${utils.fontSize(value)}`}>{utils.counter(value)}</div>
                    </div>
                </div>
            )
        }
    }


    render = () => {

        const { ante, isChoise, trips, backColor, add, gameplay, action, disabled } = this.props

        const colorStyle = { backgroundColor: backColor }

        const anteAnimation = isChoise && !ante ? "animated" : ""
        const tripsAnimation = isChoise && ante && !trips ? "animated" : ""

        const anteAvailable = isChoise ? "cursor" : ""
        const tripsAvailable = isChoise && ante ? "cursor" : ""

        const disabledTrips = !ante ? "disabledTrips" : ""

        const style = gameplay ? "game" : ""

        const chipsStyle = disabled ? "disabled" : ""

        const pass = action === 'pass'
        return (
            <div className={`ultimate-chips-bottom ${style}`}>
                <div className={`trips-box ${disabledTrips}`}>
                    <div className='trips-rhombus-outer'>
                        <div className='trips-rhombus-inner'>
                            <div className={`r-trips ${tripsAnimation}`} style={colorStyle} />
                        </div>
                    </div>
                    <div className={`trips ${tripsAvailable}`} onClick={() => add('trips')}>
                        {this.image('trips')}
                        {this.imageResult('trips')}
                    </div>
                </div>
                <div className={`ultimate ${chipsStyle}`}>
                    <div className="ante-blind">
                        <div className='rhombus-outer'>
                            <div className='rhombus-inner'>
                                <div className={`rhombus ante ${anteAnimation}`} style={colorStyle} />
                                <div className={`rhombus blind ${anteAnimation}`} style={colorStyle} />
                            </div>
                        </div>

                        <div className='front'>
                            <div className={`ante ${anteAvailable}`} onClick={() => add('ante')}>
                                {this.image('ante', pass)}
                                {this.imageResult('ante')}
                            </div>
                            <div className={`blind ${anteAvailable}`} onClick={() => add('ante')}>
                                {this.image('blind', pass)}
                                {this.imageResult('blind')}
                            </div>
                        </div>
                    </div>
                    <div className='holdem-bet'>
                        <div className='holdem-bet-outer'>
                            <div className='holdem-bet-inner' style={colorStyle} />
                        </div>
                        <div className='bet-inner'>
                            {this._bet()}
                            {this._betResult()}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default UltimateRates